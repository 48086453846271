// TunnelContext.js

import React, { createContext, useContext, useReducer, useEffect, useMemo, useCallback } from 'react';
import { tunnelInfos as initialTunnelInfos } from './data/tunnelInfos';
import { tunnelFees as initialTunnelFees } from './data/tunnelFees';
import { tunnelDistances as initialTunnelDistances } from './data/tunnelDistances';
import { tunnelIcons as initialTunnelIcons } from './data/tunnelIcons';
import { sortTunnelByDistance } from './utils/tunnelSortingByDistance';

const TunnelContext = createContext();

const safeJSONParse = (key, defaultValue) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : defaultValue;
  } catch (error) {
    console.error(`Error parsing ${key} from localStorage:`, error);
    return defaultValue;
  }
};

const getInitialState = () => {
  const savedSettings = safeJSONParse('appSettings', {});

  return {
    tunnelInfos: initialTunnelInfos,
    tunnelFees: initialTunnelFees,
    tunnelDistances: initialTunnelDistances,
    tunnelIcons: initialTunnelIcons,
    vehicle: savedSettings.vehicle || 'car',
    language: savedSettings.language || 'en',
    gpsEnabled: savedSettings.gpsEnabled || false,
    debugFeeEnabled: savedSettings.debugFeeEnabled || false,
    debugGpsEnabled: savedSettings.debugGpsEnabled || false,
    randomDistanceEnabled: savedSettings.randomDistanceEnabled,
    randomFeeEnabled: savedSettings.randomFeeEnabled,
    updateInterval: 3000,
  };
};

const initialState = getInitialState();

const tunnelReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload };
    case 'SET_VEHICLE':
      return { ...state, vehicle: action.payload };
    case 'SET_DEBUG_FEE':
      return { ...state, debugFeeEnabled: action.payload };
    case 'SET_DEBUG_GPS':
      return { ...state, debugGpsEnabled: action.payload };
    case 'SET_GPS_ENABLED':
      return {
        ...state,
        gpsEnabled: action.payload,
        tunnelDistances: action.payload ? state.tunnelDistances : initialTunnelDistances
      };
    case 'SET_RANDOM_DISTANCE':
      return { ...state, randomDistanceEnabled: action.payload };
    case 'SET_RANDOM_FEE':
      return { ...state, randomFeeEnabled: action.payload };
    case 'UPDATE_TUNNEL_FEES':
      return {
        ...state,
        tunnelFees: state.tunnelFees.map(fee => {
          const updatedFee = action.payload.find(update => update.abbr === fee.abbr);
          return updatedFee ? { ...fee, ...updatedFee } : fee;
        })
      };
    case 'UPDATE_TUNNEL_DISTANCES':
      return {
        ...state,
        tunnelDistances: state.tunnelDistances.map(distance => {
          const updatedDistance = action.payload.find(update => update.abbr === distance.abbr);
          return updatedDistance ? { ...distance, ...updatedDistance } : distance;
        })
      };
    case 'UPDATE_TUNNEL_ICONS':
      return { ...state, tunnelIcons: action.payload };
    default:
      return state;
  }
};

export const TunnelProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tunnelReducer, initialState);

  useEffect(() => {
    const savedSettings = localStorage.getItem('appSettings');
    if (savedSettings) {
      const settings = JSON.parse(savedSettings);
      dispatch({ type: 'SET_VEHICLE', payload: settings.vehicle ?? 'car' });
      dispatch({ type: 'SET_GPS_ENABLED', payload: settings.gpsEnabled ?? false });
      dispatch({ type: 'SET_LANGUAGE', payload: settings.language ?? 'en' });
      dispatch({ type: 'SET_DEBUG_FEE', payload: settings.debugFeeEnabled ?? false });
      dispatch({ type: 'SET_DEBUG_GPS', payload: settings.debugGpsEnabled ?? false });
      dispatch({ type: 'SET_RANDOM_DISTANCE', payload: settings.randomDistanceEnabled ?? false });
      dispatch({ type: 'SET_RANDOM_FEE', payload: settings.randomFeeEnabled ?? false });
    }
  }, []);

  useEffect(() => {
    const settings = {

      vehicle: state.vehicle,
      gpsEnabled: state.gpsEnabled,
      language: state.language,
      debugFeeEnabled: state.debugFeeEnabled,
      debugGpsEnabled: state.debugGpsEnabled,
      randomDistanceEnabled: state.randomDistanceEnabled,
      randomFeeEnabled: state.randomFeeEnabled,

    };
    localStorage.setItem('appSettings', JSON.stringify(settings));
  }, [state.vehicle, state.gpsEnabled, state.language, state.debugFeeEnabled, state.debugGpsEnabled, state.randomDistanceEnabled, state.randomFeeEnabled]);

  // Wrapped updateTunnelFees with useCallback
  const updateTunnelFees = useCallback((updates) => {
    dispatch({ type: 'UPDATE_TUNNEL_FEES', payload: updates });
  }, []);

  // Wrapped updateTunnelDistances with useCallback
  const updateTunnelDistances = useCallback((updates) => {
    dispatch({ type: 'UPDATE_TUNNEL_DISTANCES', payload: updates });
  }, []);

  // Added new toggleGPS function
  const toggleGPS = useCallback(() => {
    dispatch({ type: 'SET_GPS_ENABLED', payload: !state.gpsEnabled });
  }, [state.gpsEnabled]);

  const sortedTunnelDistances = useMemo(() => {
    const tunnelDistances = state.tunnelDistances.reduce((acc, curr) => {
      acc[curr.abbr] = curr.distance;
      return acc;
    }, {});
    
    const sorted = sortTunnelByDistance(tunnelDistances);
    return sorted;
  }, [state.tunnelDistances]);

  // Wrapped the value object with useMemo
  const value = useMemo(() => ({
    ...state,
    sortedTunnelDistances,
    dispatch,
    updateTunnelFees,
    updateTunnelDistances,
    toggleGPS,
  }), [state, sortedTunnelDistances, updateTunnelFees, updateTunnelDistances, toggleGPS]);

  //console.log('TunnelContext value:', value);

  return <TunnelContext.Provider value={value}>{children}</TunnelContext.Provider>;
};

export const useTunnelContext = () => {
  const context = useContext(TunnelContext);
  if (!context) {
    throw new Error('useTunnelContext must be used within a TunnelProvider');
  }
  return context;
};