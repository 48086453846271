// src/components/DebugGPS.js
import React, { useState, useEffect, useCallback } from 'react';
import { useTunnelContext } from '../TunnelContext';
import { tunnelCoordinates as initialCoordinates } from '../data/tunnelCoordinates';
import { calculateDistance, updateAllDistances } from '../utils/gpsDistanceCalculation';
import './DebugGPS.css';

const DebugGPS = () => {
  const { updateTunnelDistances, language, tunnelDistances, updateInterval } = useTunnelContext();
  const [simState, setSimState] = useState({
    startLat: 22.301389,  // WHC latitude
    startLon: 114.156667, // WHC longitude
    endLat: 22.299486,    // EHC latitude
    endLon: 114.231103,   // EHC longitude
    speed: 200,
    isSimulating: false,
    progress: 0,
  });

  const [currentPosition, setCurrentPosition] = useState({
    lat: simState.startLat,
    lon: simState.startLon,
  });

  const startSimulation = useCallback(() => {
    setSimState((prev) => ({ ...prev, isSimulating: true, progress: 0 }));
  }, []);

  const stopSimulation = useCallback(() => {
    setSimState((prev) => ({ ...prev, isSimulating: false }));
  }, []);

  const handleInputChange = useCallback((e) => {
    const { id, value } = e.target;
    setSimState((prev) => ({ ...prev, [id]: parseFloat(value) }));
  }, []);

  useEffect(() => {
    if (!simState.isSimulating) return;

    const totalDistance = calculateDistance(
      simState.startLat,
      simState.startLon,
      simState.endLat,
      simState.endLon
    );
    const totalTimeHours = totalDistance / simState.speed;
    const totalTimeMs = totalTimeHours * 3600000;
    //const updateInterval = 1000; // Update every 1 second

    const intervalId = setInterval(() => {
      setSimState((prev) => {
        const newProgress = prev.progress + updateInterval / totalTimeMs;
        if (newProgress >= 1) {
          clearInterval(intervalId);
          return { ...prev, isSimulating: false, progress: 1 };
        }
        return { ...prev, progress: newProgress };
      });

      const currentLat = simState.startLat + (simState.endLat - simState.startLat) * simState.progress;
      const currentLon = simState.startLon + (simState.endLon - simState.startLon) * simState.progress;
      const newPosition = { lat: currentLat, lon: currentLon };
      setCurrentPosition(newPosition);

      // Update all distances using the new position
      updateAllDistances(newPosition, initialCoordinates, updateTunnelDistances);

    }, updateInterval);

    return () => clearInterval(intervalId);
  }, [simState.isSimulating, simState.startLat, simState.startLon, simState.endLat, simState.endLon, simState.speed, simState.progress, updateTunnelDistances, updateInterval]);

  return (
    <div id="debugGPStool">
      <div className="debugGPS-header">
        <h3>{language === 'en' ? 'Debug GPS Tool: Simulated GPS Movement' : 'GPS除錯工具：模擬GPS移動'}</h3>
      </div>
      
      <div className="debugGPS-input-group">
        <div className="debugGPS-input-field">
          <label htmlFor="startLat">{language === 'en' ? 'Start Latitude (WHC):' : '起點緯度 (WHC)：'}</label>
          <input
            type="number"
            id="startLat"
            step="0.000001"
            value={simState.startLat}
            onChange={handleInputChange}
          />
        </div>
        <div className="debugGPS-input-field">
          <label htmlFor="startLon">{language === 'en' ? 'Start Longitude (WHC):' : '起點經度 (WHC)：'}</label>
          <input
            type="number"
            id="startLon"
            step="0.000001"
            value={simState.startLon}
            onChange={handleInputChange}
          />
        </div>
      </div>
      
      <div className="debugGPS-input-group">
        <div className="debugGPS-input-field">
          <label htmlFor="endLat">{language === 'en' ? 'End Latitude (EHC):' : '終點緯度 (EHC)：'}</label>
          <input
            type="number"
            id="endLat"
            step="0.000001"
            value={simState.endLat}
            onChange={handleInputChange}
          />
        </div>
        <div className="debugGPS-input-field">
          <label htmlFor="endLon">{language === 'en' ? 'End Longitude (EHC):' : '終點經度 (EHC)：'}</label>
          <input
            type="number"
            id="endLon"
            step="0.000001"
            value={simState.endLon}
            onChange={handleInputChange}
          />
        </div>
      </div>
      
      <div className="debugGPS-input-group">
        <div className="debugGPS-input-field">
          <label htmlFor="speed">{language === 'en' ? 'Speed (km/h):' : '速度 (公里/小時)：'}</label>
          <input
            type="range"
            id="speed"
            min="10"
            max="500"
            step="10"
            value={simState.speed}
            onChange={handleInputChange}
          />
          <span className="debugGPS-speed-value">{simState.speed} km/h</span>
        </div>
      </div>
      
      <div className="debugGPS-button-group">
        <button onClick={startSimulation} disabled={simState.isSimulating}>
          {language === 'en' ? 'Start Simulation' : '開始模擬'}
        </button>
        <button onClick={stopSimulation} disabled={!simState.isSimulating}>
          {language === 'en' ? 'Stop Simulation' : '停止模擬'}
        </button>
      </div>
      
      <div className="debugGPS-status-group">
        <p className="debugGPS-status-item">
          <span className="debugGPS-status-label">{language === 'en' ? 'Current Position:' : '當前位置：'}</span>
          <span className="debugGPS-status-value">({currentPosition.lat.toFixed(6)}, {currentPosition.lon.toFixed(6)})</span>
        </p>
      </div>

      <div className="debugGPS-progress-container">
        <div className="debugGPS-progress-label">
          <span>{language === 'en' ? 'Progress:' : '進度：'}</span>
          <span>{(simState.progress * 100).toFixed(2)}%</span>
        </div>
        <div className="debugGPS-progress-bar">
          <div 
            className="debugGPS-progress-bar-fill" 
            style={{ width: `${simState.progress * 100}%` }}
          ></div>
        </div>
      </div>

      <div className="debugGPS-tunnel-distances">
        <h4>{language === 'en' ? 'Distance to Tunnels:' : '到隧道的距離：'}</h4>
        <div className="debugGPS-tunnel-grid">
          {tunnelDistances.map((tunnel) => (
            <div key={tunnel.abbr} className="debugGPS-tunnel-item">
              <span className="debugGPS-tunnel-abbr">{tunnel.abbr}</span>
              <span className="debugGPS-tunnel-distance">
                {tunnel.distance.toFixed(2)} km
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DebugGPS;