export const tunnelFees = [
    { abbr: "WHC", fee: 60.0 },
    { abbr: "CHT", fee: 40.0 },
    { abbr: "EHC", fee: 40.0 },
    { abbr: "ABT", fee: 5.0 },
    { abbr: "SMT", fee: 5.0 },
    { abbr: "LRT", fee: 8.0 },
    { abbr: "ENT", fee: 8.0 },
    { abbr: "TCT", fee: 20.0 },
    { abbr: "TLT", fee: 58.0 }
];