// src/components/useGPSUpdater.js
import { useEffect } from 'react';
import { useTunnelContext } from '../TunnelContext';
import { tunnelCoordinates } from '../data/tunnelCoordinates';
import { tunnelDistances as initialTunnelDistances } from '../data/tunnelDistances';
import { updateAllDistances } from '../utils/gpsDistanceCalculation';

const useGPSUpdater = () => {
  const { updateTunnelDistances, gpsEnabled, language } = useTunnelContext();

  useEffect(() => {
    let watchId;

    const startWatchingPosition = () => {
      watchId = navigator.geolocation.watchPosition(
        (pos) => {
          const newPosition = {
            lat: pos.coords.latitude,
            lon: pos.coords.longitude,
          };

          // Update distances when position changes
          updateAllDistances(newPosition, tunnelCoordinates, updateTunnelDistances);
        },
        (err) => {
          console.error(language === 'en' ? 'GPS Error:' : 'GPS 錯誤:', err.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    };

    const requestLocationPermission = async () => {
      try {
        const result = await navigator.permissions.query({ name: 'geolocation' });
        if (result.state === 'granted' || result.state === 'prompt') {
          startWatchingPosition();
        } else {
          console.error(language === 'en' ? 'Location permission denied' : '位置權限被拒絕');
        }
      } catch (err) {
        console.error(language === 'en' ? 'Error requesting location permission' : '請求位置權限時出錯');
      }
    };

    if (gpsEnabled) {
      requestLocationPermission();
    } else {
      // Reset distances when GPS is turned off
      updateTunnelDistances(initialTunnelDistances);
    }

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [gpsEnabled, updateTunnelDistances, language]);

  // This hook doesn't return anything, it just performs side effects
};

export default useGPSUpdater;