import React, { useState, useEffect } from 'react';
import TunnelIcon from './TunnelIcon';
import { useTunnelContext } from '../TunnelContext'
import './TunnelItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from '@fortawesome/free-solid-svg-icons';


const TunnelItem = ({ tunnel, fee, distance, icon, }) => {
  const { language,  gpsEnabled, debugGpsEnabled, updateInterval } = useTunnelContext();
  const [distanceValue, setDistanceValue] = useState(distance);
  const [distanceFadeState, setDistanceFadeState] = useState('fade-in');
  const [feeValue, setFeeValue] = useState(fee);
  const [feeAnimationState, setFeeAnimationState] = useState('');

  useEffect(() => {
    const roundedDistance = Number(distance.toFixed(1));
    const roundedDistanceValue = Number(distanceValue.toFixed(1));
    
    if (roundedDistanceValue !== roundedDistance) {
      setDistanceFadeState('fade-out');
      const timer = setTimeout(() => {
        setDistanceValue(roundedDistance);
        setDistanceFadeState('fade-in');
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [distance, distanceValue]);

  useEffect(() => {
    if (feeValue !== fee) {
      setFeeValue(fee);  // Update the fee value immediately
  
      // Start the animation in the next frame
      requestAnimationFrame(() => {
        setFeeAnimationState('fade-animate');
      });
  
      const animationDuration = updateInterval; // 3 seconds for 3 repetitions of 1-second animation
      const timer = setTimeout(() => {
        setFeeAnimationState('');
      }, animationDuration);
  
      return () => {
        clearTimeout(timer);
      };
    }
  }, [fee, feeValue, updateInterval]);

  const getTunnelName = () => {
    if (language === 'zh') {
      return `${tunnel.nameZh}`;
    } else {
      return `${tunnel.nameEn}`;
    }
  };

  const getDistanceText = () => {
    const unit = language === 'zh' ? '公里' : 'km';
    //const label = language === 'zh' ? '距離' : 'Distance';
    return `${distance.toFixed(1)} ${unit}`;
  };

  const formatFee = (value) => {
    return Number.isInteger(value) ? value.toFixed(0) : value.toFixed(1);
  };

  return (
    <div className="tunnel">
      <div 
        className="tunnel-name" 
        data-en={tunnel.nameEn} 
        data-zh={tunnel.nameZh} 
        data-abbr={tunnel.abbr}
      >
        {getTunnelName()}
      </div>
      <div className="tunnel-info">
        {icon && <TunnelIcon svg={icon} />}
        {feeValue !== undefined && (
          <div className="toll-info">
          <div className="fee">
            <span className={`fee-text ${feeAnimationState}`}>
              {formatFee(fee)}
            </span>
          </div>
            <div className="currency">Dollar<br/>元</div>
          </div>
        )}
      </div>
      {(gpsEnabled || debugGpsEnabled) && (
        <div className="distance" unit-en="km" unit-zh="公里">
          <FontAwesomeIcon icon={faRoad} className="distance-icon" />
          <span className={`distance-value ${distanceFadeState}`}>
            {getDistanceText()}
          </span>
        </div>
      )}
    </div>
  );
};

export default TunnelItem;