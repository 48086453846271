// src/App.js

import React from 'react';
import { TunnelProvider, useTunnelContext } from './TunnelContext';
import TunnelList from './components/TunnelList';
import BottomMenu from './components/BottomMenu';
import RandomFeeGenerator from './components/RandomFeeGenerator';
import RandomDistanceGenerator from './components/RandomDistanceGenerator';
import DebugGPS from './components/DebugGPS';
import DebugTunnelFee from './components/DebugTunnelFee';
import useGPSUpdater from './components/useGPSUpdater';
import useTunnelFeeUpdater from './components/useTunnelFeeUpdater';
//import AdSense from 'react-adsense';
import './App.css';

const AppContent = () => {
  const { 
    debugFeeEnabled, 
    debugGpsEnabled, 
    randomDistanceEnabled, 
    randomFeeEnabled 
  } = useTunnelContext();

  useTunnelFeeUpdater();
  useGPSUpdater();

  return (
    <div className="App">
      {randomFeeEnabled && <RandomFeeGenerator />}
      {randomDistanceEnabled && <RandomDistanceGenerator />}
      <TunnelList />
      <BottomMenu />
      {debugGpsEnabled && <DebugGPS />}
      {debugFeeEnabled && <DebugTunnelFee />}

    </div>
  );


};

const App = () => {
  return (
    <TunnelProvider>
      <AppContent />
    </TunnelProvider>
  );
};

export default App;