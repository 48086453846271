// src/components/Settings.js
import React, { useState } from 'react';
import './Settings.css';
import ToggleButton from './ToggleButton';
import { faCog, faBug, faMapMarkerAlt, faRuler, faCoins, faComments } from '@fortawesome/free-solid-svg-icons';
import { useTunnelContext } from '../TunnelContext';
import { tunnelDistances as initialTunnelDistances } from '../data/tunnelDistances';

const Settings = () => {
  const [showSettings, setShowSettings] = useState(false);
  const { 
    language, 
    debugFeeEnabled, 
    debugGpsEnabled, 
    randomDistanceEnabled,
    randomFeeEnabled,
    dispatch,
    updateTunnelDistances,
  } = useTunnelContext();

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const getStatusText = (isEnabled) => {
    return language === 'en' ? (isEnabled ? 'On' : 'Off') : (isEnabled ? '開啟' : '關閉');
  };

  const openFeedbackForm = () => {
    window.open('https://bdkwesf8.forms.app/hk-toll-fee-app-user-feedback-form', '_blank', 'noopener,noreferrer');
  };

  const settingsItems = [
    {
      icon: faBug,
      text: `${language === 'en' ? 'Debug Fee' : '調試費用'} ${getStatusText(debugFeeEnabled)}`,
      onClick: () => dispatch({ type: 'SET_DEBUG_FEE', payload: !debugFeeEnabled }),
      isActive: debugFeeEnabled
    },
    {
      icon: faMapMarkerAlt,
      text: `${language === 'en' ? 'Debug GPS' : '調試GPS'} ${getStatusText(debugGpsEnabled)}`,
      onClick: () => {
        dispatch({ type: 'SET_DEBUG_GPS', payload: !debugGpsEnabled });
        if (debugGpsEnabled) {
          updateTunnelDistances(initialTunnelDistances);
        }
      },
      isActive: debugGpsEnabled
    },
    {
      icon: faRuler,
      text: `${language === 'en' ? 'Random Distance' : '隨機距離'} ${getStatusText(randomDistanceEnabled)}`,
      onClick: () => dispatch({ type: 'SET_RANDOM_DISTANCE', payload: !randomDistanceEnabled }),
      isActive: randomDistanceEnabled
    },
    {
      icon: faCoins,
      text: `${language === 'en' ? 'Random Fee' : '隨機費用'} ${getStatusText(randomFeeEnabled)}`,
      onClick: () => dispatch({ type: 'SET_RANDOM_FEE', payload: !randomFeeEnabled }),
      isActive: randomFeeEnabled
    },
    {
      icon: faComments,
      text: language === 'en' ? 'Feedback' : '用戶反饋',
      onClick: openFeedbackForm,
      isActive: false
    }
  ];

  return (
    <>
      <div className={`settings-dropdown ${showSettings ? 'show' : ''}`}>
        {settingsItems.map((item, index) => (
          <ToggleButton key={index} {...item} />
        ))}
      </div>

      <ToggleButton
        icon={faCog}
        text={language === 'en' ? 'Settings' : '設置'}
        onClick={toggleSettings}
        isActive={showSettings}
      />
    </>
  );
};

export default Settings;