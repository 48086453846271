// src/components/RandomFeeGenerator.js
import { useEffect, useCallback } from 'react';
import { useTunnelContext } from '../TunnelContext';

const RandomFeeGenerator = () => {
  const { tunnelFees, updateTunnelFees, randomFeeEnabled, updateInterval } = useTunnelContext();

  const generateRandomFee = () => {
    return (Math.random() * 90 + 10).toFixed(2);
  };

  const updateAllFees = useCallback(() => {
    if (!randomFeeEnabled) return;

    const updatedFees = tunnelFees.map(fee => ({
      ...fee,
      fee: parseFloat(generateRandomFee())
    }));

    updateTunnelFees(updatedFees);
  }, [tunnelFees, updateTunnelFees, randomFeeEnabled]);

  useEffect(() => {
    if (!randomFeeEnabled) return;

    const interval = setInterval(() => {
      updateAllFees();
    }, updateInterval);

    return () => clearInterval(interval);
  }, [updateAllFees, randomFeeEnabled, updateInterval]);

  return null;
};

export default RandomFeeGenerator;