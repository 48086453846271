// src/utils/calculateTunnelFees.js

import { isHoliday } from '../data/hkHolidays';
import { tunnelTimeRangeFees } from '../data/tunnelTimeRangeFees';

function calculateFee(dateTime, tunnel, vehicle) {
  //console.log(dateTime);
  
  // Convert to Hong Kong Time
  const hktDateTime = new Date(dateTime.toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' }));
  
  const hours = hktDateTime.getHours();
  const minutes = hktDateTime.getMinutes();
  const seconds = hktDateTime.getSeconds();
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  
  const dayType = isHoliday(hktDateTime) ? 'holiday' : 'weekday';
  
  const tunnelData = tunnelTimeRangeFees.find(t => t.abbr === tunnel);
  if (!tunnelData) {
    console.log('No tunnel data found');
    return null;
  }

  const ranges = tunnelData[dayType];

  for (const range of ranges) {
    const [startHours, startMinutes, startSeconds = 0] = range.start.split(':').map(Number);
    const [endHours, endMinutes, endSeconds = 59] = range.end.split(':').map(Number);
    const startTotalSeconds = startHours * 3600 + startMinutes * 60 + startSeconds;
    const endTotalSeconds = endHours * 3600 + endMinutes * 60 + endSeconds;  
    if (totalSeconds >= startTotalSeconds && totalSeconds <= endTotalSeconds) {
      let fee;

      switch (tunnel) {
        case 'TCT':
        case 'TLT':
          fee = range.fee[vehicle];
          break;

        case 'WHC':
        case 'CHT':
        case 'EHC':
          if (typeof range.fee === 'number') {
            fee = range.fee;
          } else if (dayType === 'holiday') {
            fee = range.fee;
          } else {
            const minutesPassed = Math.floor((totalSeconds - startTotalSeconds) / 60);
            const feeChangeInterval = Math.floor(minutesPassed / 2);
            const feeChange = feeChangeInterval * 2 * (range.fee.end > range.fee.start ? 1 : -1);
            fee = range.fee.start + feeChange;
          }
          
          switch (vehicle) {
            case 'motorcycle':
              fee = Math.round(fee * 0.4 * 10) / 10;
              break;
            case 'car':
              // No change for cars
              break;
            // Add more vehicle types here as needed
            default:
              console.log('Unknown vehicle type');
              return null;
          }
          break;

        default:
          fee = range.fee;
          break;
      }

      return Math.round(fee * 10) / 10;
    }
  }

  console.log('No matching time range found');
  return null;
}

export const calculateTunnelFees = (dateTime, vehicle) => {
  // Ensure dateTime is a Date object
  const date = dateTime instanceof Date ? dateTime : new Date(dateTime);
  
  return tunnelTimeRangeFees.map(tunnel => {
    const fee = calculateFee(date, tunnel.abbr, vehicle);
    return {
      abbr: tunnel.abbr,
      fee: fee
    };
  }).filter(tunnel => tunnel.fee !== null);
};