// src/components/RandomDistanceGenerator.js
import { useEffect, useCallback } from 'react';
import { useTunnelContext } from '../TunnelContext';

const RandomDistanceGenerator = () => {
  const { tunnelDistances, updateTunnelDistances, randomDistanceEnabled, updateInterval } = useTunnelContext();

  const generateRandomDistance = () => {
    return parseFloat((Math.random() * 10 + 1).toFixed(1));
  };

  const updateAllDistances = useCallback(() => {
    if (!randomDistanceEnabled) return;

    const updatedDistances = tunnelDistances.map(distance => ({
      abbr: distance.abbr,
      distance: generateRandomDistance()
    }));

    updateTunnelDistances(updatedDistances);
  }, [tunnelDistances, updateTunnelDistances, randomDistanceEnabled]);

  useEffect(() => {
    if (!randomDistanceEnabled) return;

    const interval = setInterval(() => {
      updateAllDistances();
    }, updateInterval);

    return () => clearInterval(interval);
  }, [updateAllDistances, randomDistanceEnabled, updateInterval]);

  return null;
};

export default RandomDistanceGenerator;