import React, { useState, useEffect, useCallback } from 'react';
import { useTunnelContext } from '../TunnelContext';
import { calculateTunnelFees } from '../utils/calculateTunnelFees';
import './DebugTunnelFee.css';

const DebugTunnelFee = () => {
  const { tunnelFees, updateTunnelFees, language, vehicle } = useTunnelContext();

  // Initialize with Hong Kong Time
  const initialDebugDatetime = new Date(2024, 7, 1, 7, 20); // 07:20 HKT
  
  const formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [debugState, setDebugState] = useState({
    debugDatetime: formatDateForInput(initialDebugDatetime),
    timeAcceleration: 10,
    isSimulating: false,
  });

  const [currentDebugDatetime, setCurrentDebugDatetime] = useState(initialDebugDatetime);

  const startSimulation = useCallback(() => {
    setDebugState((prev) => ({ ...prev, isSimulating: true }));
    const debugDate = new Date(debugState.debugDatetime.replace('T', ' '));
    setCurrentDebugDatetime(debugDate);
  }, [debugState.debugDatetime]);

  const stopSimulation = useCallback(() => {
    setDebugState((prev) => ({ ...prev, isSimulating: false }));
  }, []);

  const handleInputChange = useCallback((e) => {
    const { id, value } = e.target;
    if (id === 'debugDatetime') {
      setDebugState((prev) => ({ ...prev, [id]: value }));
      const debugDate = new Date(value.replace('T', ' '));
      setCurrentDebugDatetime(debugDate);
    } else if (id === 'timeAcceleration') {
      setDebugState((prev) => ({ ...prev, timeAcceleration: parseInt(value, 10) }));
    }
  }, []);


  useEffect(() => {
    if (!debugState.isSimulating) return;

    const intervalId = setInterval(() => {
      setCurrentDebugDatetime((prevTime) => {
        return new Date(prevTime.getTime() + debugState.timeAcceleration * 6000);
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [debugState.isSimulating, debugState.timeAcceleration]);

  useEffect(() => {
    if (debugState.isSimulating) {
      const calculatedFees = calculateTunnelFees(currentDebugDatetime, vehicle);
      updateTunnelFees(calculatedFees);
    }
  }, [currentDebugDatetime, debugState.isSimulating, vehicle, updateTunnelFees]);

  const formattedDebugDatetime = currentDebugDatetime.toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' });

  const renderDebugTunnelFees = tunnelFees.map((tunnel) => (
    <div key={tunnel.abbr} className="debugTunnelFee-item">
      <span className="debugTunnelFee-abbr">{tunnel.abbr}</span>
      <span className="debugTunnelFee-fee">
        ${tunnel.fee?.toFixed(2) || 'N/A'}
      </span>
    </div>
  ));

  return (
    <div id="debugTunnelFee-tool">
      <div className="debugTunnelFee-header">
        <h3>{language === 'en' ? 'Debug Tunnel Fee Tool: Simulated Datetime (HKT)' : '隧道收費除錯工具：模擬日期時間（香港時間）'}</h3>
      </div>
      
      <div className="debugTunnelFee-input-group">
        <div className="debugTunnelFee-input-field">
          <label htmlFor="debugDatetime">{language === 'en' ? 'Debug Date and Time (HKT):' : '除錯日期和時間（香港時間）：'}</label>
          <input
            type="datetime-local"
            id="debugDatetime"
            value={debugState.debugDatetime}
            onChange={handleInputChange}
          />
        </div>
      </div>
      
      <div className="debugTunnelFee-input-group">
        <div className="debugTunnelFee-input-field">
          <label htmlFor="timeAcceleration">{language === 'en' ? 'Time Acceleration:' : '時間加速：'}</label>
          <input
            type="range"
            id="timeAcceleration"
            min="1"
            max="600"
            step="1"
            value={debugState.timeAcceleration}
            onChange={handleInputChange}
          />
          <span className="debugTunnelFee-acceleration-value">
            {debugState.timeAcceleration === 1
              ? '1x (real-time)'
              : `${debugState.timeAcceleration}x (${(debugState.timeAcceleration / 10).toFixed(1)} min/sec)`}
          </span>
        </div>
      </div>
      
      <div className="debugTunnelFee-button-group">
        <button onClick={startSimulation} disabled={debugState.isSimulating}>
          {language === 'en' ? 'Start Simulation' : '開始模擬'}
        </button>
        <button onClick={stopSimulation} disabled={!debugState.isSimulating}>
          {language === 'en' ? 'Stop Simulation' : '停止模擬'}
        </button>
      </div>
      
      <div className="debugTunnelFee-status-group">
        <p className="debugTunnelFee-status-item">
          <span className="debugTunnelFee-status-label">{language === 'en' ? 'Current Simulated Datetime (HKT):' : '當前模擬日期時間（香港時間）：'}</span>
          <span className="debugTunnelFee-status-value">{formattedDebugDatetime}</span>
        </p>
      </div>

      <div className="debugTunnelFee-fees">
        <h4>{language === 'en' ? 'Current Tunnel Fees:' : '當前隧道收費：'}</h4>
        <div className="debugTunnelFee-grid">
          {renderDebugTunnelFees}
        </div>
      </div>
    </div>
  );
};

export default DebugTunnelFee;