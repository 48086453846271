// src/components/TunnelList.js
import React, { useEffect, useState, useRef } from 'react';
import TunnelItem from './TunnelItem';
import { useTunnelContext } from '../TunnelContext';
import './TunnelList.css';

const TunnelList = () => {
  const context = useTunnelContext();
  const [prevPositions, setPrevPositions] = useState({});
  const listRef = useRef(null);
  //useEffect(() => {
  //  console.log('TunnelList - Full context:', context);
  //}, [context]);

  const { 
    tunnelInfos, 
    tunnelFees, 
    tunnelDistances, 
    tunnelIcons,  
    sortedTunnelDistances 
  } = context;

  useEffect(() => {
    const newPositions = {};
    sortedTunnelDistances.forEach((item, index) => {
      const abbr = typeof item === 'string' ? item : item.abbr;
      newPositions[abbr] = index;
    });

    setPrevPositions(newPositions);
  }, [sortedTunnelDistances]);

  useEffect(() => {
    const list = listRef.current;
    if (list) {
      const items = Array.from(list.children);
      items.forEach(item => {
        item.style.transition = 'none';
        item.style.transform = `translateY(${item.dataset.prevY}px)`;
      });
      
      // Force reflow
      void list.offsetHeight;

      items.forEach(item => {
        item.style.transition = `transform 0.5s ease`;
        item.style.transform = `translateY(${item.dataset.y}px)`;
      });
    }
  }, [sortedTunnelDistances]);


  return (
    //<div id="tunnelList">
    <div id="tunnelList" ref={listRef} style={{ position: 'relative', height: `${sortedTunnelDistances.length * 180}px` }}>
      {sortedTunnelDistances.map((item, index) => {
        const abbr = typeof item === 'string' ? item : item.abbr;
        
        const tunnel = tunnelInfos.find(info => info.abbr === abbr);
        const fee = tunnelFees.find(f => f.abbr === abbr)?.fee;
        const distance = tunnelDistances.find(d => d.abbr === abbr)?.distance;
        const icon = tunnelIcons.find(i => i.abbr === abbr)?.svg;

        const prevY = (prevPositions[abbr] || 0) * 180;
        const currentY = index * 180;

        return (
          <div
            key={abbr}
            className="tunnel-item"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }}
            data-prev-y={prevY}
            data-y={currentY}
          >
          <TunnelItem
            key={abbr}
            tunnel={tunnel}
            fee={fee}
            distance={distance}
            icon={icon}
          />
          </div>
        );


      })}
    </div>
  );

};

export default TunnelList;