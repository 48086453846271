export const tunnelInfos = [
    { nameEn: "Western Harbour Crossing", nameZh: "西區海底隧道", abbr: "WHC" },
    { nameEn: "Cross-Harbour Tunnel", nameZh: "紅磡海底隧道", abbr: "CHT" },
    { nameEn: "Eastern Harbour Crossing", nameZh: "東區海底隧道", abbr: "EHC" },
    { nameEn: "Aberdeen Tunnel", nameZh: "香港仔隧道", abbr: "ABT" },
    { nameEn: "Shing Mun Tunnels", nameZh: "城門隧道", abbr: "SMT" },
    { nameEn: "Lion Rock Tunnel", nameZh: "獅子山隧道", abbr: "LRT" },
    { nameEn: "Eagle's Nest Tunnel", nameZh: "尖山隧道", abbr: "ENT" },
    { nameEn: "Tate's Cairn Tunnel", nameZh: "大老山隧道", abbr: "TCT" },
    { nameEn: "Tai Lam Tunnel", nameZh: "大欖隧道", abbr: "TLT" }
  ];