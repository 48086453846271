// src/components/ToggleButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ToggleButton.css';

const ToggleButton = ({ icon, text, onClick, isActive }) => {
  return (
    <button 
      onClick={onClick} 
      className={`toggle-button ${isActive ? 'active' : ''}`}
      aria-pressed={isActive}
    >
      <FontAwesomeIcon icon={icon} className="toggle-button-icon" />
      <span>{text}</span>
    </button>
  );
};

export default ToggleButton;