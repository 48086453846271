export const tunnelCoordinates = [
    { abbr: "WHC", lat: 22.301389, lon: 114.156667 },
    { abbr: "CHT", lat: 22.291389, lon: 114.182222 },
    { abbr: "EHC", lat: 22.299486, lon: 114.231103 },
    { abbr: "ABT", lat: 22.26086, lon: 114.18108 },
    { abbr: "SMT", lat: 22.3788, lon: 114.1587 },
    { abbr: "LRT", lat: 22.355833, lon: 114.172778 },
    { abbr: "ENT", lat: 22.35358, lon: 114.15992 },
    { abbr: "TCT", lat: 22.364, lon: 114.2119 },
    { abbr: "TLT", lat: 22.394999, lon: 114.060831 }
];