// src/utils/gpsDistanceCalculation.js

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
};

export const updateAllDistances = (position, tunnelCoordinates, updateTunnelDistances) => {
  const updatedDistances = tunnelCoordinates.map(tunnel => ({
    abbr: tunnel.abbr,
    distance: calculateDistance(position.lat, position.lon, tunnel.lat, tunnel.lon)
  }));
  updateTunnelDistances(updatedDistances);
};