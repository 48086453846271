// src/components/BottomMenu.js
import React from 'react';
import ToggleButton from './ToggleButton';
import CycleButton from './CycleButton';
import Settings from './Settings';
import { useTunnelContext } from '../TunnelContext';
import { faLanguage, faLocationArrow, faCar, faMotorcycle, faTaxi } from '@fortawesome/free-solid-svg-icons';
import './BottomMenu.css';

const BottomMenu = () => {
  const { language, vehicle, gpsEnabled, dispatch } = useTunnelContext();

  const getStatusText = (isEnabled) => {
    return language === 'en' ? (isEnabled ? 'On' : 'Off') : (isEnabled ? '開啟' : '關閉');
  };

  const vehicleOptions = [
    { value: 'car', label: language === 'en' ? 'Private Car' : '私家車' },
    { value: 'motorcycle', label: language === 'en' ? 'Motorcycle' : '電單車' },
    //{ value: 'taxi', label: language === 'en' ? 'Taxi' : '的士' }
  ];

  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'zh', label: '中文' }
  ];

  const getVehicleIcon = (vehicleType) => {
    switch (vehicleType) {
      case 'car':
        return faCar;
      case 'motorcycle':
        return faMotorcycle;
      case 'taxi':
        return faTaxi;
      default:
        return faCar;
    }
  };

  const bottomMenuItems = [
    {
      component: CycleButton,
      props: {
        icon: getVehicleIcon(vehicle),
        options: vehicleOptions,
        currentOption: vehicle,
        onChange: (value) => dispatch({ type: 'SET_VEHICLE', payload: value })
      }
    },
    {
      component: ToggleButton,
      props: {
        icon: faLocationArrow,
        text: `GPS ${getStatusText(gpsEnabled)}`,
        onClick: () => dispatch({ type: 'SET_GPS_ENABLED', payload: !gpsEnabled }),
        isActive: gpsEnabled,
      }
    },
    {
      component: CycleButton,
      props: {
        icon: faLanguage,
        options: languageOptions,
        currentOption: language,
        onChange: (value) => dispatch({ type: 'SET_LANGUAGE', payload: value })
      }
    }
  ];

  return (
    <div className="bottom-menu">
      {bottomMenuItems.map((item, index) => {
        const ItemComponent = item.component;
        return <ItemComponent key={index} {...item.props} />;
      })}
      <Settings />
    </div>
  );
};

export default BottomMenu;