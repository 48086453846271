export const hkHolidays = [
    // 2024 Holidays
    '2024-01-01', // The first day of January (Monday)
    '2024-02-10', // Lunar New Year's Day (Saturday)
    '2024-02-12', // The third day of Lunar New Year (Monday)
    '2024-02-13', // The fourth day of Lunar New Year (Tuesday)
    '2024-03-29', // Good Friday (Friday)
    '2024-03-30', // The day following Good Friday (Saturday)
    '2024-04-01', // Easter Monday (Monday)
    '2024-04-04', // Ching Ming Festival (Thursday)
    '2024-05-01', // Labour Day (Wednesday)
    '2024-05-15', // The Birthday of the Buddha (Wednesday)
    '2024-06-10', // Tuen Ng Festival (Monday)
    '2024-07-01', // Hong Kong Special Administrative Region Establishment Day (Monday)
    '2024-09-18', // The day following the Chinese Mid-Autumn Festival (Wednesday)
    '2024-10-01', // National Day (Tuesday)
    '2024-10-11', // Chung Yeung Festival (Friday)
    '2024-12-25', // Christmas Day (Wednesday)
    '2024-12-26', // The first weekday after Christmas Day (Thursday)
  
    // 2025 Holidays
    '2025-01-01', // The first day of January (Wednesday)
    '2025-01-29', // Lunar New Year's Day (Wednesday)
    '2025-01-30', // The second day of Lunar New Year (Thursday)
    '2025-01-31', // The third day of Lunar New Year (Friday)
    '2025-04-04', // Ching Ming Festival (Friday)
    '2025-04-18', // Good Friday (Friday)
    '2025-04-19', // The day following Good Friday (Saturday)
    '2025-04-21', // Easter Monday (Monday)
    '2025-05-01', // Labour Day (Thursday)
    '2025-05-05', // The Birthday of the Buddha (Monday)
    '2025-05-31', // Tuen Ng Festival (Saturday)
    '2025-07-01', // Hong Kong Special Administrative Region Establishment Day (Tuesday)
    '2025-10-01', // National Day (Wednesday)
    '2025-10-07', // The day following the Chinese Mid-Autumn Festival (Tuesday)
    '2025-10-29', // Chung Yeung Festival (Wednesday)
    '2025-12-25', // Christmas Day (Thursday)
    '2025-12-26', // The first weekday after Christmas Day (Friday)
  ];
  
  export function isHoliday(date) {
    const day = date.getDay();
    if (day === 0) return true; // Sunday is always a holiday
  
    const dateString = date.toISOString().split('T')[0];
    return hkHolidays.includes(dateString);
  }