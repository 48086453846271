export const tunnelTimeRangeFees = [
    {
        abbr: "WHC",
        weekday: [
            { start: '00:00', end: '07:29:59', fee: 20, slot: 'offPeak' },
            { start: '07:30', end: '08:07:59', fee: { start: 22, end: 58 }, slot: 'transition' },
            { start: '08:08', end: '10:14:59', fee: 60, slot: 'peak' },
            { start: '10:15', end: '10:42:59', fee: { start: 58, end: 28 }, slot: 'transition' },
            { start: '10:43', end: '16:29:59', fee: 30, slot: 'normal' },
            { start: '16:30', end: '16:57:59', fee: { start: 32, end: 58 }, slot: 'transition' },
            { start: '16:58', end: '18:59:59', fee: 60, slot: 'peak' },
            { start: '19:00', end: '19:37:59', fee: { start: 58, end: 22 }, slot: 'transition' },
            { start: '19:38', end: '23:59:59', fee: 20, slot: 'offPeak' }
        ],
        holiday: [
            { start: '00:00', end: '10:10:59', fee: 20, slot: 'offPeak' },
            { start: '10:11', end: '10:12:59', fee: 21, slot: 'transition' },
            { start: '10:13', end: '10:14:59', fee: 23, slot: 'transition' },
            { start: '10:15', end: '19:14:59', fee: 25, slot: 'normal' },
            { start: '19:15', end: '19:16:59', fee: 23, slot: 'transition' },
            { start: '19:17', end: '19:18:59', fee: 21, slot: 'transition' },
            { start: '19:19', end: '23:59:59', fee: 20, slot: 'offPeak' }
        ]
    },
    {
        abbr: "CHT",
        weekday: [
            { start: '00:00', end: '07:29:59', fee: 20, slot: 'offPeak' },
            { start: '07:30', end: '07:47:59', fee: { start: 22, end: 38 }, slot: 'transition' },
            { start: '07:48', end: '10:14:59', fee: 40, slot: 'peak' },
            { start: '10:15', end: '10:22:59', fee: { start: 38, end: 32 }, slot: 'transition' },
            { start: '10:23', end: '16:29:59', fee: 30, slot: 'normal' },
            { start: '16:30', end: '16:37:59', fee: { start: 32, end: 38 }, slot: 'transition' },
            { start: '16:38', end: '18:59:59', fee: 40, slot: 'peak' },
            { start: '19:00', end: '19:17:59', fee: { start: 38, end: 22 }, slot: 'transition' },
            { start: '19:18', end: '23:59:59', fee: 20, slot: 'offPeak' }
        ],
        holiday: [
            { start: '00:00', end: '10:10:59', fee: 20, slot: 'offPeak' },
            { start: '10:11', end: '10:12:59', fee: 21, slot: 'transition' },
            { start: '10:13', end: '10:14:59', fee: 23, slot: 'transition' },
            { start: '10:15', end: '19:14:59', fee: 25, slot: 'normal' },
            { start: '19:15', end: '19:16:59', fee: 23, slot: 'transition' },
            { start: '19:17', end: '19:18:59', fee: 21, slot: 'transition' },
            { start: '19:19', end: '23:59:59', fee: 20, slot: 'offPeak' }
        ]
    },
    {
        abbr: "EHC",
        weekday: [
            { start: '00:00', end: '07:29:59', fee: 20, slot: 'offPeak' },
            { start: '07:30', end: '07:47:59', fee: { start: 22, end: 38 }, slot: 'transition' },
            { start: '07:48', end: '10:14:59', fee: 40, slot: 'peak' },
            { start: '10:15', end: '10:22:59', fee: { start: 38, end: 32 }, slot: 'transition' },
            { start: '10:23', end: '16:29:59', fee: 30, slot: 'normal' },
            { start: '16:30', end: '16:37:59', fee: { start: 32, end: 38 }, slot: 'transition' },
            { start: '16:38', end: '18:59:59', fee: 40, slot: 'peak' },
            { start: '19:00', end: '19:17:59', fee: { start: 38, end: 22 }, slot: 'transition' },
            { start: '19:18', end: '23:59:59', fee: 20, slot: 'offPeak' }
        ],
        holiday: [
            { start: '00:00', end: '10:10:59', fee: 20, slot: 'offPeak' },
            { start: '10:11', end: '10:12:59', fee: 21, slot: 'transition' },
            { start: '10:13', end: '10:14:59', fee: 23, slot: 'transition' },
            { start: '10:15', end: '19:14:59', fee: 25, slot: 'normal' },
            { start: '19:15', end: '19:16:59', fee: 23, slot: 'transition' },
            { start: '19:17', end: '19:18:59', fee: 21, slot: 'transition' },
            { start: '19:19', end: '23:59:59', fee: 20, slot: 'offPeak' }
        ]
    },
    {
        abbr: "ABT",
        weekday: [
            { start: '00:00', end: '23:59:59', fee: 5 }
        ],
        holiday: [
            { start: '00:00', end: '23:59:59', fee: 5 }
        ]
    },
    {
        abbr: "SMT",
        weekday: [
            { start: '00:00', end: '23:59:59', fee: 5 }
        ],
        holiday: [
            { start: '00:00', end: '23:59:59', fee: 5 }
        ]
    },
    {
        abbr: "LRT",
        weekday: [
            { start: '00:00', end: '23:59:59', fee: 8 }
        ],
        holiday: [
            { start: '00:00', end: '23:59:59', fee: 8 }
        ]
    },
    {
        abbr: "ENT",
        weekday: [
            { start: '00:00', end: '23:59:59', fee: 8 }
        ],
        holiday: [
            { start: '00:00', end: '23:59:59', fee: 8 }
        ]
    },
    {
        abbr: "TCT",
        weekday: [
            { start: '00:00', end: '23:59:59', fee: { motorcycle: 15, car: 20 } }
        ],
        holiday: [
            { start: '00:00', end: '23:59:59', fee: { motorcycle: 15, car: 20 } }
        ]
    },
    {
        abbr: "TLT",
        weekday: [
            { start: '00:00', end: '23:59:59', fee: { motorcycle: 28, car: 58 } }
        ],
        holiday: [
            { start: '00:00', end: '23:59:59', fee: { motorcycle: 28, car: 58 } }
        ]
    }
];