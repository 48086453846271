import { useEffect } from 'react';
import { useTunnelContext } from '../TunnelContext';
import { calculateTunnelFees } from '../utils/calculateTunnelFees';

export const useTunnelFeeUpdater = () => {
  const { tunnelFees, updateTunnelFees, vehicle, updateInterval, debugFeeEnabled } = useTunnelContext();

  useEffect(() => {
    const updateFees = () => {
      if (!debugFeeEnabled) {
        const currentDateTime = new Date();
        const calculatedFees = calculateTunnelFees(currentDateTime, vehicle);
        updateTunnelFees(calculatedFees);
      }
    };

    // Initial update
    updateFees();

    let intervalId;
    if (!debugFeeEnabled) {
      intervalId = setInterval(updateFees, updateInterval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [updateTunnelFees, vehicle, debugFeeEnabled, updateInterval]);

  return { tunnelFees, updateTunnelFees };
};

export default useTunnelFeeUpdater;