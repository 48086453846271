export const tunnelDistances = [
    { abbr: "WHC", distance: 4.1 },
    { abbr: "CHT", distance: 2.1 },
    { abbr: "EHC", distance: 3.6 },
    { abbr: "ABT", distance: 5.2 },
    { abbr: "SMT", distance: 9.1 },
    { abbr: "LRT", distance: 6.2 },
    { abbr: "ENT", distance: 6.6 },
    { abbr: "TCT", distance: 6.7 },
    { abbr: "TLT", distance: 17.2 }
];