// src/components/CycleButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CycleButton.css';

const CycleButton = ({ icon, options, currentOption, onChange, text }) => {
  const handleClick = () => {
    const currentIndex = options.findIndex(option => option.value === currentOption);
    const nextIndex = (currentIndex + 1) % options.length;
    onChange(options[nextIndex].value);
  };

  const currentOptionObj = options.find(option => option.value === currentOption);

  return (
    <button 
      onClick={handleClick} 
      className="cycle-button"
      aria-pressed={currentOption !== options[0].value}
    >
      <FontAwesomeIcon icon={icon} className="cycle-button-icon" />
      <span className="cycle-button-status">{currentOptionObj ? currentOptionObj.label : ''}</span> 
    </button>
  );
};

export default CycleButton;